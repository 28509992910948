import React, { useState, useEffect } from "react";
import { FaAngleUp } from "react-icons/fa";

function BackToTop() {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="top-to-btm">
      {/* <div className="fixed z-10 bottom-28 left-2">
        <a
          href="https://porch.com/near-me/tree-contractors?bestProId=51152848"
          target="_blank"
          rel="
        noreferrer"
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/cursosjavascript-f54e8.appspot.com/o/owner-background-checked.png?alt=media&token=46e9b9a2-5194-45f7-8dd8-554da792c5d6"
            alt="PORCH"
            className="w-[75px] py-2 shadow-2xl"
          />
        </a>
        <a
          href="https://porch.com/bay-shore-ny/tree-contractors/american-pride-tree-experts-and-landscaping-inc/pp"
          target="_blank"
          rel="
        noreferrer"
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/cursosjavascript-f54e8.appspot.com/o/featured-pro.png?alt=media&token=5bca11ce-0d22-4801-87a1-0d00980bf6af"
            alt="PORCH"
            className="w-[75px] py-2 shadow-2xl"
          />
        </a>
      </div>{" "} */}
      {showTopBtn && (
        <FaAngleUp className="icon-position icon-style" onClick={goToTop} />
      )}{" "}
    </div>
  );
}

export default BackToTop;
